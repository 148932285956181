import './shared/serviceWorker';
import './shared/adInsertion';
import './shared/universal-header';
import './shared/stream-share';
import './sharing-config';
import './shared/pushlyPrompt';
import { setupCordialService } from './shared/cordialData';

import './profile/listuser-ad-service';
import './profile/slider';
import './profile/connections-carousel';
import './profile/sticky-video';
import './profile/newsworthy';
import './profile/profile-video';
import './profile/publish-submit-profile';
import './profile/sticky-container-sizing';
import './profile/truncate-bio';
import './profile/truncate-alternative-bio';
import './profile/profile-form';
import './profile/person-networthchart';
import './profile/top-ad-util';
import './profile/profile-tracking';
import './profile/truncate-lists';
import './profile/lists-block';
import './profile/charity-stats';
import './profile/datapoint-tabs';
import './profile/stats-info-icon-click';
import './profile/connections-block';
import './profile/investment-q-factor';
import './profile/financial-summary';
import './profile/awards-description.js';
import './profile/company-spotlight.js';
import './profile/credentials-utilities';
import './profile/entrypoint/expanded-card.js';
import './profile/react/profileCarousel.js';

setupCordialService();
